import type QueueSummaryData from "~/api/response/data/queue/QueueSummaryData";

export default class QueueSummary {
    count: number;
    duration: number | null;
    throughput: number | null;

    constructor(data: QueueSummaryData) {
        this.count = data.count;
        this.duration = data.duration;
        this.throughput = data.throughput;
    }
}