import type CheckpointSummaryData from "~/api/response/data/checkpoint/SummaryData";
import CheckpointTotal from "~/dto/checkpoint/Total";
import {CheckpointStatus} from "~/dto/Checkpoint";
import QueuesSummary from "~/dto/queue/QueuesSummary";

export default class CheckpointSummary {
    code: string;
    name: string;
    status: CheckpointStatus;
    countryCode: string;
    total: CheckpointTotal | null;
    hasQueues: boolean;
    carsQueues: QueuesSummary | null;
    motorcyclesQueues: QueuesSummary | null;

    constructor(data: CheckpointSummaryData) {
        this.code = data.code;
        this.name = data.name;
        this.status = data.status as CheckpointStatus;
        this.countryCode = data.country_code;
        this.total = data.total ? new CheckpointTotal(data.total) : null;
        this.hasQueues = data.has_queues;
        this.carsQueues = data.cars_queues ? new QueuesSummary(data.cars_queues) : null;
        this.motorcyclesQueues = data.motorcycles_queues ? new QueuesSummary(data.motorcycles_queues) : null;
    }

    get isOpen() {
        return CheckpointStatus.Open === this.status;
    }

    get isClosed() {
        return CheckpointStatus.Closed === this.status;
    }
}