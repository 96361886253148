import type QueuesSummaryData from "~/api/response/data/queue/QueuesSummaryData";
import QueueSummary from "~/dto/queue/QueueSummary";

export default class QueuesSummary {
    live: QueueSummary;
    priority: QueueSummary | null;

    constructor(data: QueuesSummaryData) {
        this.live = new QueueSummary(data.live);
        this.priority = data.priority ? new QueueSummary(data.priority) : null;
    }

    get total() {
        return this.live.count + (this.priority?.count || 0);
    }
}