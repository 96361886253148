import type QueueItemData from "~/api/response/data/queue/QueueItemData";
import Checkpoint from "~/dto/Checkpoint";
import Queue from "~/dto/queue/Queue";
import { DateTime } from "luxon";

export const enum Status {
    Arrived = 'arrived',
    Called = 'called',
    Canceled = 'canceled',
}

export default class QueueItem {
    queue: Queue;
    registrationNumber: string;
    callingOrder: number | null;
    status: string;
    registeredAt: DateTime;
    calledAt: DateTime | null;
    checkpoint: Checkpoint | null;

    constructor(data: QueueItemData) {
        this.queue = new Queue(data.queue);
        this.registrationNumber = data.registration_number;
        this.callingOrder = data.calling_order;
        this.status = data.status as Status;
        this.registeredAt = DateTime.fromISO(data.registered_at);
        this.calledAt = data.called_at ? DateTime.fromISO(data.called_at) : null;
        this.checkpoint = data.checkpoint ? new Checkpoint(data.checkpoint) : null;
    }

    get isArrived() {
        return Status.Arrived === this.status;
    }

    get isCalled() {
        return Status.Called === this.status;
    }

    get isCanceled() {
        return Status.Canceled === this.status;
    }
}