import type QueueData from "~/api/response/data/queue/QueueData";

export const enum QueueType {
    Cars = 'cars',
    Motorcycles = 'motorcycles',
}

export const enum QueuePriority {
    Live = 'live',
    Priority = 'priority',
}

export default class Queue {
    type: QueueType;
    priority: QueuePriority;

    constructor(data: QueueData) {
        this.type = data.type as QueueType;
        this.priority = data.priority as QueuePriority;
    }
}